import React from "react";

import "../../scss/molecules/_footer.scss";
import ritornoLogo from "../../../static/design/img/ritorno.svg";

const Header = () => (
  <footer className="footer">
    <div className="container">
      <div className="row">
        <div className="col-md-6 order-2 text-right">
          <ul className="soc-list">
            {/* <li>
              <a href="https://twitter.com/pastaritorno" target="_blank">
                <i className="icon icon-twitter2" />
              </a>
            </li> */}
            <li>
              <a href="https://www.facebook.com/ritorno.be/" target="_blank">
                <i className="icon icon-facebook2" />
              </a>
            </li>
            {/* <li>
              <a href="https://www.pinterest.com/ritorno/" target="_blank">
                <i className="icon icon-pinterest" />
              </a>
            </li> */}
            <li>
              <a
                href="https://www.instagram.com/ritorno_pastakraam/"
                target="_blank"
              >
                <i className="icon icon-instagram" />
              </a>
            </li>
          </ul>
        </div>
        <div className="col-md-6 order-1">
          <img
            className="logo float-left mr-1"
            src={ritornoLogo}
            alt="ritorno logo, mobiel pastakraam"
            height="50"
          />
          <span className="editContent">
            © 2019
            <br />
            <a href="https://www.thebrownfox.be/" target="_blank">
              Design by The Brown Fox{" "}
              <img
                src="https://www.thebrownfox.be/design/img/logo/logo-thebrownfox-black.svg"
                height="18"
                alt="Logo the brown fox, your freelance web designer"
              />
            </a>
          </span>
        </div>
      </div>
    </div>
  </footer>
);

export default Header;
