import React from "react";
import "../../scss/molecules/_cookie-banner.scss";

class CookieBanner extends React.Component {
  constructor(props) {
    super(props);

    this.marketingRef = React.createRef();

    this.state = {
      accepted: false,
      marketingAccepted: false,
      shown: 0,
    };
    this.updateStateWithLocalStorage();
    this.updateCount();
  }
  updateStateWithLocalStorage() {
    // https://www.cookiebot.com/en/google-tag-manager-gdpr/

    if (typeof window !== "undefined") {
      let shown = localStorage.getItem("cookiebanner-shown");
      if (shown) {
        this.state.shown = parseInt(shown);
      }

      let accepted = localStorage.getItem("cookiebanner-accepted");
      if (accepted) {
        this.state.accepted = true;
        if (window.dataLayer !== undefined) {
          window.dataLayer.push({ event: "cookieconsent_statistics" });
          console.debug("dataLayer push: ", "cookieconsent_statistics");
        }
      }

      let marketingAccepted = localStorage.getItem(
        "cookiebanner-marketing-accepted"
      );
      if (marketingAccepted) {
        this.state.marketingAccepted = true;
        if (window.dataLayer !== undefined) {
          window.dataLayer.push({ event: "cookieconsent_marketing" });
          console.debug("dataLayer push: ", "cookieconsent_marketing");
        }
      }
    }
  }
  updateCount() {
    if (typeof window !== "undefined") {
      if (!this.state.accepted) {
        this.state.shown = this.state.shown + 1;
        localStorage.setItem("cookiebanner-shown", this.state.shown);
      }
    }
  }
  accept() {
    if (typeof window !== "undefined") {
      this.setState({ accepted: true });
      localStorage.setItem("cookiebanner-accepted", true);

      this.setState({ marketingAccepted: this.marketingRef.current.checked });
      localStorage.setItem(
        "cookiebanner-marketing-accepted",
        this.marketingRef.current.checked
      );
    }
  }
  render() {
    if (this.state.accepted) {
      return "";
    }
    return (
      <>
        <section className="cookie-banner">
          <div className="container">
            <div className="cookie-banner-body">
              <p>
                Onze site maakt gebruik van cookies. Door onze site te blijven
                gebruiken gaat u akkoord met onze gegevensbescherming.
              </p>
              <div className="form-group">
                <label htmlFor="marketing-cookies">Marketing cookies</label>
                <input
                  type="checkbox"
                  ref={this.marketingRef}
                  name="marketing-cookies"
                />
              </div>
              <a
                className="btn btn-primary"
                onClick={() => {
                  this.accept();
                }}
              >
                Aanvaarden &amp; sluiten
              </a>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default CookieBanner;
