import React from "react";

import Slogan from "../molecules/slogan";

import "../../scss/molecules/_intro-block.scss";

export default class Header extends React.Component {
  render() {
    return (
      <header className="intro-block">
        <Slogan
          heading={this.props.heading}
          headerImage={this.props.image}
          darkBg={this.props.darkBg}
        />
        {this.props.children}
      </header>
    );
  }
}
