import React from "react";
import { Link } from "gatsby";

import ritornoLogo from "../../../static/design/img/ritorno.svg";
import ritornoWhiteLogo from "../../../static/design/img/ritorno-white.svg";
import headerImage from "../../../static/design/img/header/tafel-ritorno-landscape.jpg";

import "../../scss/molecules/_slogan.scss";

class Slogan extends React.Component {
  render() {
    return (
      <section
        className={"slogan pt-5 pb-7" + (this.props.darkBg ? " dark-bg" : "")}
        style={{
          backgroundImage: `url(
                  ${this.props.headerImage || headerImage}
              )`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h1>
                <img
                  src={this.props.darkBg ? ritornoWhiteLogo : ritornoLogo}
                  alt="Ritorno logo"
                  className="image"
                />
                <small>{this.props.heading || "Mobiele pasta"}</small>
              </h1>
              <Link to="/contacteer-ons" className="btn btn-secondary">
                Contacteer ons
                <i className="icon-paper-plane" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Slogan;
