import React from "react";
import { Link } from "gatsby";

import "../../scss/molecules/_navigation.scss";

import ritornoLogo from "../../../static/design/img/ritorno.svg";

export default class Nav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }
  toggleMenu() {
    this.setState({ open: !this.state.open });
  }
  render() {
    return (
      <nav className="navbar bg-color1 dark-bg">
        <div className="container">
          <Link to="/" className="navbar-brand goto">
            <img
              src={ritornoLogo}
              className="image"
              alt="Ritorno logo"
              height="40"
            />
          </Link>
          <button
            className="navbar-toggle"
            onClick={() => {
              this.toggleMenu();
            }}
          >
            <span className="navbar-toggle-icon"></span>
            <span className="navbar-toggle-icon"></span>
            <span className="navbar-toggle-icon"></span>
          </button>
          <ul className={"nav" + (this.state.open ? "" : " collapse")}>
            <li>
              <Link to="/waarom-ritorno" className="nav-link">
                Waarom Ritorno
              </Link>
            </li>
            <li>
              <Link to="/feest-organiseren" className="nav-link">
                Feest organiseren
              </Link>
            </li>
            <li>
              <Link to="/sfeer" className="nav-link">
                Sfeer
              </Link>
            </li>
            <li>
              <Link to="/faq" className="nav-link">
                Faq
              </Link>
            </li>
            <li>
              <Link to="/contacteer-ons" className="nav-link btn btn-primary">
                Contacteer ons
                <i className="icon-paper-plane" />
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}
