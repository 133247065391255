import React from "react";
import { Link } from "gatsby";

import "../../scss/molecules/_form.scss";
import "../../scss/molecules/_contact.scss";

export default class Contact extends React.Component {
  constructor(props) {
    super(props);

    let containerClass = "pattern-bg contact";
    let containerStyle = {
      backgroundImage: "url(/design/img/pattern-background.png)",
    };
    if (this.props.dark == "true") {
      containerClass = "dark-bg section-dark contact";
      containerStyle = {};
    }

    this.state = {
      containerClass: containerClass,
      containerStyle: containerStyle,
    };
  }
  render() {
    const phone = "0032 (0)3 502 51 61";
    const email = "info@ritorno.be";
    return (
      <section
        className={this.state.containerClass}
        style={this.state.containerStyle}
      >
        <div className="container pt-4 pb-5">
          <div className="row">
            <div className="col-lg-8 col-md-10">
              <h2 className="title mb-2">Contacteer ons</h2>
              <p className="mb-2">
                U kan ons steeds contacteren via mail{" "}
                <a href={"mailto:" + email} className="goto">
                  {email}
                </a>{" "}
                of bel ons op het nummer{" "}
                <a href={"tel:" + phone} className="goto">
                  {phone}
                </a>
              </p>
              <Link to="/contacteer-ons" className="btn btn-primary">
                Naar het contactformulier
                <i className="icon-paper-plane" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
